import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getLatestPalletInfo = createAsyncThunk(
	"/palletLatest",
	async ({ id }, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			const pallet = id
				? await api(`/api/pallets/${id}?with_polygons=true`, {
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
				  })
				: (
						await api(
							`/api/pallets?is_inspected=true&limit=1&with_polygons=true&desc=true`,
							{
								method: "GET",
								headers: {
									"Content-Type": "application/json",
								},
							}
						)
				  )[0];
			const [batch, scheme, image] = await Promise.all([
				api(
					`/api/batches/interval?limit=1&dt_end=${encodeURIComponent(
						pallet.dt
					)}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}
				),
				api(`/api/pallets/${pallet.uuid}/scheme`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}),
				api(`/api/pallets/${pallet.uuid}/image`, {
					method: "GET",
					headers: {
						"Content-Type": "image/webp",
					},
				}),
			]);

			return { pallet, batch, scheme, image };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getClickedPallet = createAsyncThunk(
	"/palletById",
	async ({ id }, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			const [pallet, scheme, image] = await Promise.all([
				api(`/api/pallets/${id}`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}),
				api(`/api/pallets/${id}/scheme`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}),
				api(`/api/pallets/${id}/image`, {
					method: "GET",
					headers: {
						"Content-Type": "image/webp",
					},
				}),
			]);
			return { pallet, scheme, image };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getLatestChart = createAsyncThunk(
	"/historyLatest",
	async ({ id }, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			const currentTime = new Date().toISOString();
			const [batch, scheme] = await Promise.all([
				api(
					`/api/batches/interval?limit=1&dt_end=${encodeURIComponent(
						currentTime.slice(0, currentTime.length - 1) + "+00:00"
					)}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}
				),
				api(`/api/pallets/${id}/scheme`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}),
			]);

			return { scheme, batch };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getSectorInfo = createAsyncThunk(
	"/sectorInfo",
	async ({ palletId, crop, sectorId }, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			const [imageCrop, decision] = await Promise.all([
				api(
					`/api/pallets/${palletId}/image?crop=%5B${crop[0]}%2C%20${crop[1]}%2C%20${crop[2]}%2C%20${crop[3]}%5D`,
					{
						method: "GET",
						headers: {
							"Content-Type": "image/webp",
						},
					}
				),
				api(`/api/pallets/${palletId}/${sectorId}/decision`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}),
			]);

			return { imageCrop, decision };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const updateAfterDecision = createAsyncThunk(
	"/getSectorDecision",
	async ({ palletId }, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			const pallet = (
				await api(
					`/api/pallets?is_inspected=true&limit=1&with_polygons=true&desc=true`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}
				)
			)[0];
			const [scheme, chart] = await Promise.all([
				await api(`/api/pallets/${palletId}/scheme`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}),
				await api(`/api/pallets/${pallet.uuid}/scheme`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}),
			]);

			return { scheme, chart };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const setDecisionBySector = createAsyncThunk(
	"/setSectorDecision",
	async (
		{ palletId, decisionDetails, sectorId },
		{ extra, rejectWithValue, dispatch }
	) => {
		const { api } = extra;
		try {
			await api(`/api/pallets/${palletId}/${sectorId}/decision`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					decision: decisionDetails.decision,
					defect_classes: decisionDetails.defect_classes,
				}),
			});
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getNewBatchInfo = createAsyncThunk(
	"/newBatch/get",
	async (_, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			return await api(`/api/events/new_batch`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const changeNewBatchInfo = createAsyncThunk(
	"/newBatch/change",
	async (
		{ decision, product_id = "", color_id = "" },
		{ extra, rejectWithValue }
	) => {
		const { api } = extra;
		try {
			return await api(`/api/events/new_batch`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					decision,
					product_id,
					color_id,
				}),
			});
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const pointCloudDownload = createAsyncThunk(
	"/inspection/pointCloud",
	async ({ palletId }, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			const cloud = await api(`/api/pallets/${palletId}/cloud/download`, {
				method: "GET",
				headers: {
					"Content-Type": "application/octet-stream",
				},
			});
			return { cloud, palletId };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

const initialState = {
	uuid: null,
	pallet: null,
	palletStatus: "pending",
	historyStatus: "pending",
	sectorStatus: "pending",
	isHistoryOnly: false,
	decisionStatus: "pending",
	cloudStatus: "fulfilled",
	selectedPointClouds: [],
	scheme: null,
	image: null,
	imageCrop: null,
	decisionBySector: null,
	batch: null,
	chart: null,
	newBatchStatus: "pending",
	newBatchFlag: false,
	suggestedMoldId: null,
	suggestedColorId: null,
	possibleMolds: [],
	possibleColors: [],
};

const opPanelSlice = createSlice({
	name: "opPanel",
	initialState,
	reducers: {
		setNewBatchFlag: (state, action) => {
			state.newBatchFlag = action.payload;
		},
		setUuid: (state, action) => {
			state.uuid = action.payload;
		},
		clearState: (state) => {
			state = initialState;
		},
		setIsHistoryOnly: (state, action) => {
			state.isHistoryOnly = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getLatestPalletInfo.pending, (state) => {
				state.palletStatus = "pending";
				state.historyStatus = "pending";
				state.scheme = null;
			})
			.addCase(getLatestPalletInfo.fulfilled, (state, action) => {
				state.pallet = action.payload.pallet;
				if (!state.uuid) state.uuid = action.payload.pallet.uuid;
				state.image = action.payload.image;
				state.scheme = action.payload.scheme;
				state.chart = action.payload.scheme.chart;
				state.batch = action.payload.batch;
				state.palletStatus = "fulfilled";
				state.historyStatus = "fulfilled";
			})
			.addCase(getLatestPalletInfo.rejected, (state) => {
				state.palletStatus = "rejected";
			})
			.addCase(getClickedPallet.pending, (state) => {
				state.palletStatus = "pending";
			})
			.addCase(getClickedPallet.fulfilled, (state, action) => {
				state.pallet = action.payload.pallet;
				state.image = action.payload.image;
				state.scheme = action.payload.scheme;
				state.palletStatus = "fulfilled";
			})
			.addCase(getClickedPallet.rejected, (state) => {
				state.palletStatus = "rejected";
			})
			.addCase(getLatestChart.pending, (state) => {
				state.historyStatus = "pending";
			})
			.addCase(getLatestChart.fulfilled, (state, action) => {
				state.chart = action.payload.scheme.chart;
				state.batch = action.payload.batch;
				state.historyStatus = "fulfilled";
			})
			.addCase(getLatestChart.rejected, (state) => {
				state.historyStatus = "rejected";
			})
			.addCase(getSectorInfo.pending, (state) => {
				state.sectorStatus = "pending";
			})
			.addCase(getSectorInfo.fulfilled, (state, action) => {
				state.imageCrop = action.payload.imageCrop;
				state.decisionBySector = action.payload.decision;
				state.sectorStatus = "fulfilled";
			})
			.addCase(getSectorInfo.rejected, (state) => {
				state.sectorStatus = "rejected";
			})
			.addCase(setDecisionBySector.pending, (state) => {
				state.decisionStatus = "pending";
			})
			.addCase(setDecisionBySector.rejected, (state) => {
				state.decisionStatus = "rejected";
			})
			.addCase(updateAfterDecision.fulfilled, (state, action) => {
				state.decisionStatus = "fulfilled";
				state.scheme = action.payload.scheme;
				state.chart = action.payload.chart.chart;
			})
			.addCase(updateAfterDecision.rejected, (state) => {
				state.decisionStatus = "rejected";
			})
			.addCase(getNewBatchInfo.fulfilled, (state, action) => {
				state.newBatchFlag = true;
				state.suggestedMoldId = action.payload.product_id;
				state.suggestedColorId = action.payload.color_id;
				state.possibleMolds = action.payload.top_forms;
				state.possibleColors = action.payload.top_colors;
			})
			.addCase(changeNewBatchInfo.pending, (state) => {
				state.newBatchStatus = "pending";
			})
			.addCase(changeNewBatchInfo.fulfilled, (state) => {
				state.newBatchStatus = "fulfilled";
				state.newBatchFlag = false;
			})
			.addCase(changeNewBatchInfo.rejected, (state) => {
				state.newBatchStatus = "rejected";
			})
			.addCase(pointCloudDownload.pending, (state) => {
				state.cloudStatus = "pending";
			})
			.addCase(pointCloudDownload.fulfilled, (state, action) => {
				state.cloudStatus = "fulfilled";
				state.selectedPointClouds.push(action.payload);
				if (state.selectedPointClouds.length > 5)
					state.selectedPointClouds.shift();
			})
			.addCase(pointCloudDownload.rejected, (state) => {
				state.cloudStatus = "rejected";
			});
	},
});

export const { setUuid, clearState, setIsHistoryOnly, setNewBatchFlag } =
	opPanelSlice.actions;

export default opPanelSlice.reducer;
