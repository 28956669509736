import {
	Box,
	Button,
	TextField,
	Typography,
	MenuItem,
	InputAdornment,
} from "@mui/material";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DefaultSnackbar from "../profile/Snackbar";
import { useTranslation } from "react-i18next";
import {
	getExamplesByLayoutId,
	getProductById,
	deleteExampleByLayoutId,
	addExampleByLayoutId,
	updateLayoutById,
	deleteLayoutById,
} from "./LayoutFetch";
import DefaultLayoutExampleZipImage from "./LayoutImageExample";
import styled from "styled-components";
import { PicturesSelectionForm } from "./PicturesSelectionForm";
import DefaultAlertDialog from "../UI/DefaultDialog";
import { useSelector } from "react-redux";

const InputPair = styled.div`
	display: flex;
	gap: 1rem;
	width: 640px;
`;

const UpdateForm = () => {
	const { id } = useParams();
	const { formList } = useSelector((state) => state.color);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [examples, setExamples] = useState([]);
	const [isBeingEdited, setIsBeingEdited] = useState(false);
	const [examplesToAdd, setExamplesToAdd] = useState([]);
	const [examplesToRemove, setExamplesToRemove] = useState([]);
	const [showAlert, setShowAlert] = useState({
		open: false,
		onAccept: undefined,
		entity: "",
	});

	const [product, setProduct] = useState({
		name: "",
		entire_surface: "",
		unit_type: "m2",
		textureless: true,
		stone_border_width: 20,
		height: "",
		display_name: "",
	});

	const [params, setParams] = useState({
		nominal_volume: "",
		nominal_height: "",
	});

	const [settings, setSettings] = useState({
		height_min_threshold: "",
		height_max_threshold: "",
		height_min_recomended: "",
		height_max_recomended: "",
		density_min_threshold: "",
		density_max_threshold: "",
		textureless_defect_threshold: 0,
	});

	const numberRegex = /^[0-9]*?$/;
	const numberWithFractionsRegex = /^([0-9]*[.])?[0-9]*?$/;

	const fetchData = async () => {
		let productById = formList.find((product) => product.uuid === id);
		if (!productById) productById = await getProductById(id);
		setProduct(productById);
		setSettings(productById.settings);
		setParams(productById.params);
		const examplesById = await getExamplesByLayoutId(id).catch((e) =>
			console.log(1)
		);
		setExamples(examplesById ?? []);
	};

	useEffect(() => {
		fetchData();
	}, []);

	const [showSnack, setShowSnack] = useState({
		show: false,
		success: false,
		msg: "msg",
	});

	function validate() {
		const examplesCount =
			examples.length - examplesToRemove.length + examplesToAdd.length;
		if (examplesCount > 0 && examplesCount < 3) {
			return [false, t("qpbc.entities.layout.too-small-examples-set")];
		}
		return [true, null];
	}

	const textHandler = (field, handler) => (event) => {
		if (event.target.value.includes("/")) return;
		handler((prev) => ({
			...prev,
			[field]: event.target.value,
		}));
	};

	const numericHandler = (field, handler, numberRegex) => (event) => {
		if (numberRegex.test(event.target.value)) {
			handler((prev) => ({
				...prev,
				[field]:
					event.target.value.length > 1
						? event.target.value.replace(/^0+(?=\d)/, "")
						: event.target.value,
			}));
		}
	};

	function editLayoutUnit(event) {
		setProduct((prev) => ({ ...prev, unit_type: event.target.value }));
	}

	const deleteLayout = async (layout_id) => {
		try {
			await deleteLayoutById(layout_id);
		} catch (error) {
			setShowSnack({
				show: true,
				success: false,
				msg: t("qpbc.entities.layout.error-delete-failed"),
			});
			return;
		}
		setShowSnack({
			show: true,
			success: true,
			msg: t("qpbc.entities.layout.error-delete-success"),
		});
		navigate("/settings/form");
	};

	const showConfirmationDialog = (layout_id, entity) => {
		setShowAlert({
			open: true,
			onAccept: () => {
				deleteLayout(layout_id);
				handleClose();
			},
			entity: entity,
		});
	};

	const handleClose = () => {
		setShowAlert({ ...showAlert, open: false });
	};

	const appendAddExample = (pallet_id) => {
		setExamplesToAdd((examples) => [...examples, pallet_id]);
	};

	const removeAddExample = (pallet_id) => {
		setExamplesToAdd((examples) =>
			examples.filter((pid) => pid !== pallet_id)
		);
	};

	const appendDeleteExample = (image_ref) => {
		setExamplesToRemove((examples) => [...examples, image_ref]);
	};

	const removeDeleteExample = (image_ref) => {
		setExamplesToRemove((examples) =>
			examples.filter((pid) => pid !== image_ref)
		);
	};

	const confirmChanges = async () => {
		const validationResults = validate();
		const result = {
			...product,
			settings,
			params,
		};
		if (!validationResults[0]) {
			setShowSnack({
				show: true,
				success: false,
				msg: validationResults[1],
			});
		} else {
			await Promise.all([
				...examplesToRemove.map((example) =>
					deleteExampleByLayoutId(example)
				),
			]);
			await Promise.all([
				...examplesToAdd.map((example) =>
					addExampleByLayoutId(id, example)
				),
			]);
			updateLayoutById(id, result)
				.then(() => {
					setIsBeingEdited(false);
					setExamplesToAdd([]);
					setExamplesToRemove([]);
					fetchData();
					setShowSnack({
						show: true,
						success: true,
						msg: t("qpbc.entities.layout.layoutDataUpdateSuccess"),
					});
				})
				.catch(() => {
					setShowSnack({
						show: true,
						success: false,
						msg: t("qpbc.entities.layout.layoutDataUpdateError"),
					});
				});
		}
	};

	return (
		<Box sx={{ padding: "3rem 1rem", position: "relative" }}>
			<Typography
				sx={{
					fontSize: "22px",
					fontWeight: "600",
					paddingBottom: "1.5rem",
				}}
			>
				{t("qpbc.entities.layout.self")}
			</Typography>
			<Box display={"flex"} marginBottom={"2rem"} gap={"3rem"}>
				{isBeingEdited ? (
					<>
						<Button
							sx={{ color: "green" }}
							onClick={confirmChanges}
						>
							{t("profile.apply")}
						</Button>
						<Button
							onClick={() => {
								setExamplesToAdd([]);
								setExamplesToRemove([]);
								setIsBeingEdited(false);
							}}
						>
							{t("cancel")}
						</Button>
					</>
				) : (
					<>
						<Button
							onClick={() => {
								setIsBeingEdited(true);
							}}
						>
							{t("color.modal_edit_btn")}
						</Button>
						<Button
							onClick={() => {
								showConfirmationDialog(
									product.uuid,
									product.name
								);
							}}
							sx={{ color: "red" }}
						>
							{t("qpbc.entities.layout.delete")}
						</Button>
					</>
				)}
			</Box>
			<Box
				display={"flex"}
				flexDirection={"column"}
				maxWidth={"fit-content"}
				gap={"2rem"}
			>
				<InputPair>
					<TextField
						sx={{ flex: 1 }}
						disabled={!isBeingEdited}
						label={t("qpbc.entities.layout.name")}
						value={product.name}
						onChange={textHandler("name", setProduct)}
						InputLabelProps={{ shrink: true }}
					/>
				</InputPair>
				<InputPair>
					<TextField
						disabled={!isBeingEdited}
						sx={{ flex: 1 }}
						label={t("surface")}
						value={product.entire_surface}
						onChange={numericHandler(
							"entire_surface",
							setProduct,
							numberWithFractionsRegex
						)}
						InputLabelProps={{ shrink: true }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.m2")}</p>
								</InputAdornment>
							),
						}}
					/>
					<TextField
						sx={{ flex: 1 }}
						disabled={!isBeingEdited}
						label={t("qpbc.settings.expected_height_setting")}
						value={product.height}
						onChange={numericHandler(
							"height",
							setProduct,
							numberWithFractionsRegex
						)}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.mm")}</p>
								</InputAdornment>
							),
						}}
						InputLabelProps={{ shrink: true }}
					/>
				</InputPair>
			</Box>
			<Box
				sx={{
					fontSize: "22px",
					fontWeight: "600",
					paddingBottom: "2rem",
					paddingTop: "1rem",
				}}
			>
				<Box sx={{ paddingTop: "1rem", marginBottom: "1rem" }}>
					<Typography sx={{ fontSize: "16px" }}>
						{t("qpbc.entities.layout.unit-type")}
					</Typography>
					<Select
						label={t("qpbc.entities.layout.unit-type")}
						disabled={!isBeingEdited}
						value={product.unit_type}
						onChange={editLayoutUnit}
					>
						<MenuItem value={"m2"}>
							{t("qpbc.entities.layout.m2-unit-type")}
						</MenuItem>
						<MenuItem value={"pcs"}>
							{t("qpbc.entities.layout.pcs-unit-type")}
						</MenuItem>
					</Select>
				</Box>
				<div>{t("qpbc.entities.layout.examples")}</div>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						gap: "1rem",
						marginTop: "1rem",
						overflowX: "scroll",
						width: "100%",
					}}
				>
					{examples.map((elem) => (
						<DefaultLayoutExampleZipImage
							onSelect={() => appendDeleteExample(elem.ref)}
							onCancel={() => removeDeleteExample(elem.ref)}
							isBeingEdited={isBeingEdited}
							key={elem.ref}
							imageRef={elem}
						/>
					))}
				</Box>
				{isBeingEdited ? (
					<PicturesSelectionForm
						header={t("qpbc.entities.layout.select-examples")}
						subHeader={t("qpbc.entities.layout.examplesHint")}
						datePickerLabel={t(
							"qpbc.entities.layout.datePickerLabel"
						)}
						appendExample={appendAddExample}
						removeExample={removeAddExample}
					/>
				) : null}
				<Box
					display={"flex"}
					gap={"1rem"}
					alignItems={"start"}
					flexDirection={"column"}
				>
					<Typography
						sx={{
							fontSize: "22px",
							fontWeight: "600",
							paddingBottom: "2rem",
							paddingTop: "2rem",
						}}
					>
						{t("qpbc.entities.form.list")}
					</Typography>
				</Box>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					minWidth: "300px",
					gap: "1rem",
					paddingY: "8px",
				}}
			>
				<InputPair>
					<TextField
						sx={{ flex: 1 }}
						disabled={!isBeingEdited}
						label={t("qpbc.entities.form.nominalHeight")}
						value={params.nominal_height}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.mm")}</p>
								</InputAdornment>
							),
						}}
						InputLabelProps={{ shrink: true }}
						onChange={numericHandler(
							"nominal_height",
							setParams,
							numberWithFractionsRegex
						)}
					/>
					<TextField
						sx={{ flex: 1 }}
						disabled={!isBeingEdited}
						label={t("qpbc.entities.form.nominalVolume")}
						value={params.nominal_volume}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.mm3")}</p>
								</InputAdornment>
							),
						}}
						InputLabelProps={{ shrink: true }}
						onChange={numericHandler(
							"nominal_volume",
							setParams,
							numberWithFractionsRegex
						)}
					/>
				</InputPair>
				{t("qpbc.settings.self")}
				<InputPair>
					<TextField
						sx={{ flex: 1 }}
						disabled={!isBeingEdited}
						label={t("qpbc.settings.height_min_setting")}
						value={settings.height_min_threshold}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.mm")}</p>
								</InputAdornment>
							),
						}}
						InputLabelProps={{ shrink: true }}
						onChange={numericHandler(
							"height_min_threshold",
							setSettings,
							numberWithFractionsRegex
						)}
					/>
					<TextField
						sx={{ flex: 1 }}
						disabled={!isBeingEdited}
						label={t("qpbc.settings.height_max_setting")}
						value={settings.height_max_threshold}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.mm")}</p>
								</InputAdornment>
							),
						}}
						InputLabelProps={{ shrink: true }}
						onChange={numericHandler(
							"height_max_threshold",
							setSettings,
							numberWithFractionsRegex
						)}
					/>
				</InputPair>
				<InputPair>
					<TextField
						sx={{ flex: 1 }}
						disabled={!isBeingEdited}
						label={t(
							"qpbc.settings.height_min_recommended_setting"
						)}
						value={settings.height_min_recomended}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.mm")}</p>
								</InputAdornment>
							),
						}}
						InputLabelProps={{ shrink: true }}
						onChange={numericHandler(
							"height_min_recomended",
							setSettings,
							numberWithFractionsRegex
						)}
					/>
					<TextField
						sx={{ flex: 1 }}
						disabled={!isBeingEdited}
						label={t(
							"qpbc.settings.height_max_recommended_setting"
						)}
						value={settings.height_max_recomended}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.mm")}</p>
								</InputAdornment>
							),
						}}
						InputLabelProps={{ shrink: true }}
						onChange={numericHandler(
							"height_max_recomended",
							setSettings,
							numberWithFractionsRegex
						)}
					/>
				</InputPair>
				<InputPair>
					<TextField
						sx={{ flex: 1 }}
						disabled={!isBeingEdited}
						label={t("qpbc.settings.density_min_setting")}
						value={settings.density_min_threshold}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.kg/m")}</p>
								</InputAdornment>
							),
						}}
						InputLabelProps={{ shrink: true }}
						onChange={numericHandler(
							"density_min_threshold",
							setSettings,
							numberWithFractionsRegex
						)}
					/>
					<TextField
						sx={{ flex: 1 }}
						disabled={!isBeingEdited}
						label={t("qpbc.settings.density_max_setting")}
						value={settings.density_max_threshold}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.kg/m")}</p>
								</InputAdornment>
							),
						}}
						InputLabelProps={{ shrink: true }}
						onChange={numericHandler(
							"density_max_threshold",
							setSettings,
							numberWithFractionsRegex
						)}
					/>
				</InputPair>
			</Box>
			<DefaultSnackbar
				open={showSnack.show}
				setOpen={setShowSnack}
				error={showSnack.success}
				msg={showSnack.msg}
			/>
			<DefaultAlertDialog
				open={showAlert.open}
				onAccept={showAlert.onAccept}
				onClose={handleClose}
				entity={showAlert.entity}
			/>
		</Box>
	);
};

export default UpdateForm;
